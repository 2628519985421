<template>
  <dashboard-wrapper>
    <div class="loading-template" v-if="loading">
      <loading-indicator />
    </div>
    <div class="border-bottom">
      <div class="_flex _between flex-wrap py-4 px-6">
        <div class=" mt-2 _flex">
          <div @click="$router.back()" class="p-2 c-pointer">
            <i class="fal fa-arrow-left title mr-2"></i>
          </div>
          <h2 class="bold">Nouvelle oeuvre</h2>
        </div>
        <div class="_flex">
          <button class="btn black" @click="onSubmitArtWorkNow" :disabled="loading">
            <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i> Mettre en ligne
          </button>
          <button @click="" class="btn btn-outline-primary px-5 text-uppercase ml-2">
            Publier plus tard
          </button>
        </div>
      </div>
    </div>
    <!--  -->
    <fieldset :disabled="loading" class="py-8 px-6">
      <template v-if="getUserData && (getUserData.userData.type_user === 'artist')">
        <!--  -->
        <div>
          <div class="h5 bold text-uppercase mb-7">Photos de l'oeuvre</div>
          <!--  -->
          <div class="row">
            <div class="col-12 col-md-8">
              <ul class="text-muted body-1 ml-5" style="list-style:initial;max-width: 600px;">
                <li>Photo principale : oeuvre détourée - Photos n°2, 3, 4, 5 : oeuvres en situation, détails de l'oeuvre, signature, etc.</li>
                <li>2 images obligatoires sauf pour les éditions et pour les photographies.</li>
                <li>Privilégiez les images en format JPG, JPEG ou PNG.</li>
                <li>La taille de vos images doit être inférieur à 10 Mo.</li>
                <li>Vos images doivent faire au moins 500px sur un des deux côtés.</li>
              </ul>
              <!--  -->
              <div class="row mt-8">
                <div class="col-12 col-md-6">
                  <div class="image_box image_box_main" @click="onPickPicture('fileInput1')">
                    <img :src="pictures[0].pictureUrl" alt="" v-if="pictures[0].pictureUrl" style="object-fit: fill"/>
                    <div>Photo principale</div>
                    <div class="mt-2 normal">
                      <i class="icon-follow"></i>
                    </div>
                    <input type="file" style="display: none;" ref="fileInput1" accept="image/*" @change="onPicturePicked($event, 0)"/>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="row">
                    <div class="col-6">
                      <div class="image_box" @click="onPickPicture('fileInput2')">
                        <img :src="pictures[1].pictureUrl" alt="" v-if="pictures[1].pictureUrl" style="object-fit: fill"/>
                        <div>Photo n°2</div>
                        <div class="mt-2 normal"><i class="icon-follow"></i></div>
                        <input type="file" style="display: none;" ref="fileInput2" accept="image/*" @change="onPicturePicked($event, 1)"/>
                      </div>
                      <div class="image_box" @click="onPickPicture('fileInput3')">
                        <img :src="pictures[2].pictureUrl" alt="" v-if="pictures[2].pictureUrl" style="object-fit: fill"/>
                        <div>Photo n°3</div>
                        <div class="mt-2 normal"><i class="icon-follow"></i></div>
                        <input type="file" style="display: none;" ref="fileInput3" accept="image/*" @change="onPicturePicked($event, 2)"/>
                      </div>
                    </div>
                    <!--  -->
                    <div class="col-6">
                      <div class="image_box" @click="onPickPicture('fileInput4')">
                        <img :src="pictures[3].pictureUrl" alt="" v-if="pictures[3].pictureUrl" style="object-fit: fill"/>
                        <div>Photo n°4</div>
                        <div class="mt-2 normal"><i class="icon-follow"></i></div>
                        <input type="file" style="display: none;" ref="fileInput4" accept="image/*" @change="onPicturePicked($event, 3)"/>
                      </div>
                      <div class="image_box" @click="onPickPicture('fileInput5')">
                        <img :src="pictures[4].pictureUrl" alt="" v-if="pictures[4].pictureUrl" style="object-fit: fill"/>
                        <div>Photo n°5</div>
                        <div class="mt-2 normal"><i class="icon-follow"></i></div>
                        <input type="file" style="display: none;" ref="fileInput5" accept="image/*" @change="onPicturePicked($event, 4)"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Info sur l'oeuvre -->
        <div class="py-10 my-8 border-top">
          <div class="h4 bold mb-7">Informations générales</div>
          <div class="row no-gutters">
            <div class="col-12 col-lg-9">
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-8">
                  <div class="form-group">
                    <label>Nom de l'oeuvre <span class="text-danger">*</span></label>
                    <input v-model="dataForm.name" type="text" class="form-control rounded-0"/>
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <div class="form-group">
                    <label>Année de réalisation<span class="text-danger">*</span></label>
                    <select v-model="dataForm.achievement_year" class="form-control rounded-0">
                      <option value="2010">2021</option>
                      <option value="2010">2020</option>
                      <option value="2010">2019</option>
                      <option value="2010">2018</option>
                      <option value="2010">2017</option>
                      <option value="2010">2016</option>
                      <option value="2010">2015</option>
                      <option value="2010">2014</option>
                      <option value="2010">2013</option>
                      <option value="2010">2012</option>
                      <option value="2010">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                      <option value="1999">1999</option>
                      <option value="1998">1998</option>
                      <option value="1997">1997</option>
                      <option value="1996">1996</option>
                      <option value="1995">1995</option>
                      <option value="1994">1994</option>
                      <option value="1993">1993</option>
                      <option value="1992">1992</option>
                      <option value="1991">1991</option>
                      <option value="1990">1990</option>
                      <option value="1989">1989</option>
                      <option value="1988">1988</option>
                      <option value="1987">1987</option>
                      <option value="1986">1986</option>
                      <option value="1985">1985</option>
                      <option value="1984">1984</option>
                      <option value="1983">1983</option>
                      <option value="1982">1982</option>
                      <option value="1981">1981</option>
                      <option value="1980">1980</option>
                      <option value="1979">1979</option>
                      <option value="1978">1978</option>
                      <option value="1977">1977</option>
                      <option value="1976">1976</option>
                      <option value="1975">1975</option>
                      <option value="1974">1974</option>
                      <option value="1973">1973</option>
                      <option value="1972">1972</option>
                      <option value="1971">1971</option>
                      <option value="1970">1970</option>
                      <option value="1969">1969</option>
                      <option value="1968">1968</option>
                      <option value="1967">1967</option>
                      <option value="1966">1966</option>
                      <option value="1965">1965</option>
                      <option value="1964">1964</option>
                      <option value="1963">1963</option>
                      <option value="1962">1962</option>
                      <option value="1961">1961</option>
                      <option value="1960">1960</option>
                      <option value="1959">1959</option>
                      <option value="1958">1958</option>
                      <option value="1957">1957</option>
                      <option value="1956">1956</option>
                      <option value="1955">1955</option>
                      <option value="1954">1954</option>
                      <option value="1953">1953</option>
                      <option value="1952">1952</option>
                      <option value="1951">1951</option>
                      <option value="1950">1950</option>
                      <option value="1949">1949</option>
                      <option value="1948">1948</option>
                      <option value="1947">1947</option>
                      <option value="1946">1946</option>
                      <option value="1945">1945</option>
                      <option value="1944">1944</option>
                      <option value="1943">1943</option>
                      <option value="1942">1942</option>
                      <option value="1941">1941</option>
                      <option value="1940">1940</option>
                      <option value="1939">1939</option>
                      <option value="1938">1938</option>
                      <option value="1937">1937</option>
                      <option value="1936">1936</option>
                      <option value="1935">1935</option>
                      <option value="1934">1934</option>
                      <option value="1933">1933</option>
                      <option value="1932">1932</option>
                      <option value="1931">1931</option>
                      <option value="1930">1930</option>
                      <option value="1929">1929</option>
                      <option value="1928">1928</option>
                      <option value="1927">1927</option>
                      <option value="1926">1926</option>
                      <option value="1925">1925</option>
                      <option value="1924">1924</option>
                      <option value="1923">1923</option>
                      <option value="1922">1922</option>
                      <option value="1921">1921</option>
                      <option value="1920">1920</option>
                    </select>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-4 form-group" v-if="allCategories && allCategories.length > 0">
                  <label>Catégorie <span class="text-danger">*</span></label><br />
                  <select v-model="dataForm.category_id" class="custom-select rounded-0 text-capitalize" style="max-width:300px;">
                    <option :value="category.category_id" v-for="(category, idx) in allCategories" :key="category.category_id">
                      {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="col-12 col-md-6 col-lg-3 form-group">
                  <label>Poids de l'oeuvre <span class="text-danger">*</span></label><br/>
                  <!--  -->
                  <div class="_flex">
                    <!--  -->
                    <input v-model="dataForm.weight" type="number" class="form-control rounded-0 mr-1" style="width:120px;" placeholder="Poids"/>
                    <!--  -->
                    <select v-model="dataForm.weight_unit" class="custom-select rounded-0" style="max-width:120px;">
                      <option value="Kg" selected>Kg</option>
                    </select>
                  </div>
                </div>
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-6 col-lg-9 form-group">
                  <label>Dimension de l'oeuvre<span class="text-danger">*</span></label><br />
                  <div class="_flex">
                    <input v-model="dataForm.dim_height" type="text" class="form-control rounded-0" style="width:120px;" placeholder="Hauteur"/>
                    <!--  -->
                    <div class="mx-1">x</div>
                    <!--  -->
                    <input v-model="dataForm.dim_width" type="text" class="form-control rounded-0" style="width:120px;" placeholder="Largeur"/>
                    <!--  -->
                    <div class="mx-1">x</div>
                    <!--  -->
                    <input v-model="dataForm.dim_thickness" type="text" class="form-control rounded-0 mr-1" style="width:120px;" placeholder="Épaisseur"/>
                    <!--  -->
                    <select v-model="dataForm.dim_unit" class="custom-select rounded-0" style="max-width:80px;">
                      <option value="Cm" selected>Cm</option>
                    </select>
                  </div>
                </div>
                <!--  -->
              </div>
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-6 col-lg-8 form-group">
                  <label>Prix de l'oeuvre <span class="text-danger">*</span></label><br />
                  <!--  -->
                  <div class="_flex">
                    <!--  -->
                    <input v-model="dataForm.price" type="text" class="form-control rounded-0 mr-1" style="width:120px;" placeholder=""/>
                    <!--  -->
                    <select v-model="dataForm.currency_id" class="custom-select rounded-0" style="max-width:100px;">
                      <option value="usd">$ (USD)</option>
                      <option value="euro">€ (EUR)</option>
                      <option value="sterling">£ (GBP)</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <!--  -->
          <div class="h4 bold my-7">Tirage et authenticité</div>
          <div class="row no-gutters">
            <div class="col-12">
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>L'oeuvre est-elle unique ?<span class="text-danger">*</span></label><br />
                    <div class="_flex">
                      <div class="custom-control custom-radio mr-3">
                        <input v-model="dataForm.is_unique" id="is_unique_oui" type="radio" value="0" name="radio-is-unique" class="custom-control-input" required/>
                        <label for="is_unique_oui" class="custom-control-label">Non</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input v-model="dataForm.is_unique" id="is_unique_non" type="radio" value="1" name="radio-is-unique" class="custom-control-input" required/>
                        <label for="is_unique_non" class="custom-control-label">Oui</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Édition limitée et numérotée ?<span class="text-danger">*</span></label><br />
                    <div class="_flex">
                      <div class="custom-control custom-radio mr-3" data-toggle="tooltip" data-placement="top" title="Nous n'acceptions que des oeuvres à édition limitée et numérotée">
                        <input v-model="dataForm.is_limited_numbered" id="is_limited_numbered_non" type="radio" value="0" name="radio-is-limited-numbered" class="custom-control-input" required/>
                        <label for="is_limited_numbered_non" class="custom-control-label">Non</label>
                      </div>
                      <div class="custom-control custom-radio">
                        <input v-model="dataForm.is_limited_numbered" id="is_limited_numbered_oui" type="radio" value="1" name="radio-is-limited-numbered" class="custom-control-input" required/>
                        <label for="is_limited_numbered_oui" class="custom-control-label">Oui</label>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Nombre d'exemplaires total <span class="text-danger">*</span></label><br />
                    <input v-model="dataForm.total_number_copies" type="number" class="form-control rounded-0"/>
                  </div>
                </div>
                <!--  -->
                <div class="col-12 col-md-6 col-lg-3">
                  <div class="form-group">
                    <label>Nombre d'exemplaires disponibles <span class="text-danger">*</span></label><br />
                    <input v-model="dataForm.number_available_copies" type="number" class="form-control rounded-0"/>
                  </div>
                </div>
              </div>
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-6 col-lg-4 form-group texted">
                  <label>Type de signature <span class="text-danger">*</span></label><br />
                  <select v-model="dataForm.type_signature" class="custom-select rounded-0" style="max-width:300px;">
                    <option value="Non Signée">Non Signée</option>
                    <option value="Oeuvre signée à la main">Oeuvre signée à la main</option>
                  </select>
                </div>
                <!--  -->
              </div>
              <!--  -->
              <div class="h4 bold my-7">Encadrement</div>
              <!--  -->
              <div class="form-row">
                <div class="col-12 col-md-5 col-lg-3 form-group">
                  <label class="texted">Oeuvre encadrée <span class="text-danger">*</span></label><br />
                  <div class="_flex">
                    <div class="custom-control custom-radio mr-3">
                      <input v-model="dataForm.is_framed_artwork" id="is_framed_artwork_oui" type="radio" value="0" name="radio-is-framed-artwork" class="custom-control-input" required/>
                      <label for="is_framed_artwork_oui" class="custom-control-label">Non</label>
                    </div>
                    <div class="custom-control custom-radio">
                      <input v-model="dataForm.is_framed_artwork" id="is_framed_artwork_non" type="radio" value="1" name="radio-is-framed-artwork" class="custom-control-input" required/>
                      <label for="is_framed_artwork_non" class="custom-control-label">Oui</label>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div class="col-12 col-md-5 col-lg-5 form-group">
                  <label>Type d'encadrement <span class="text-danger">*</span></label><br/>
                  <select v-model="dataForm.frame_type" class="custom-select rounded-0" style="max-width:300px;">
                    <option value="pas de certificat">Pas de certificat</option>
                    <option value="certificat de l'artiste">Certificat de l'artiste</option>
                    <option value="certificat de la galerie">Certificat de la galerie</option>
                  </select>
                </div>
              </div>
              <!--  -->
            </div>
          </div>
          <!--  -->
          <div class="h5 bold my-5 text-uppercase">
            Présentation ou Description de l'oeuvre
          </div>
          <!--  -->
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <textarea v-model="dataForm.description" rows="5" class="form-control rounded-0"></textarea>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="py-10 mt-8 border-top">
            <div class="h5 bold mb-7 text-uppercase">
              Délais de livraison et provenance
            </div>
            <!--  -->
            <div class="form-row">
              <div class="col-12 col-md-6 col-lg-4 form-group texted">
                <label>Délais de livraison</label><br />
                <select v-model="dataForm.delay_delivery" class="custom-select rounded-0" style="max-width:300px;">
                  <option value="" selected>Choisissez un délais de livraison</option>
                  <option value="Moins d'une semaine">Moins d'une semaine</option>
                  <option value="Une à deux semaines">Une à deux semaines</option>
                  <option value="Deux à trois semaines">Deux à trois semaines</option>
                  <option value="Trois à quatre semaines">Trois à quatre semaines</option>
                  <option value="Quatre à cinq semaines">Quatre à cinq semaines</option>
                  <option value="Cinq à six semaines">Cinq à six semaines</option>
                  <option value="Un mois et demi">Un mois et demi</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <!-- Save -->
        <div class="d-flex px-4">
          <button class="btn black px-5 mr-4 text-uppercase" @click="onSubmitArtWorkNow">
            <i class="far fa-spinner fa-pulse mr-2" v-if="loading"></i> Mettre en ligne
          </button>
          <button class="btn btn-outline-primary px-5 text-uppercase" @click="">
            Publier plus tard
          </button>
        </div>
      </template>
      <template v-if="!getUserData && !(getUserData.userData.type_user === 'artist')">
        <dashboard-noprofile/>
      </template>
    </fieldset>
  </dashboard-wrapper>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
    dataForm: {
      user_id: "",
      category_id: "",
      name: "",
      description: "",
      achievement_year: "",
      dim_height: "",
      dim_width: "",
      dim_thickness: "",
      dim_unit: "",
      weight: "",
      weight_unit: "",
      price: "",
      tags: "",
      currency_id: "",
      is_unique: "",
      is_limited_numbered: "",
      total_number_copies: "",
      number_available_copies: "",
      type_signature: "",
      is_framed_artwork: "",
      frame_type: "",
      delay_delivery: "",
      status: "",
      created_at: "",
      updated_at: "",
      table: "addArtwork",
      table_file: "addPicture"
    },
    pictures: [
      {
        picture: null,
        pictureUrl: "",
      },
      {
        picture: null,
        pictureUrl: "",
      },
      {
        picture: null,
        pictureUrl: "",
      },
      {
        picture: null,
        pictureUrl: "",
      },
      {
        picture: null,
        pictureUrl: "",
      },
    ],
    allCategories: null,
    loading: false,
  }),
  computed: {
    ...mapGetters([
      "getToken",
      "getUserData",
    ]),
  },
  watch: {},
  methods: {
    ...mapMutations([]),
    ...mapActions([
      "axiosGetAllData",
      "axiosPostData",
      "axiosPostFileData",
    ]),
    onPickPicture($ref) {
      this.$refs[$ref].click();
    },
    onPicturePicked(e, num_pic) {
      let pictureUploaded = e.target.files[0]
      if(pictureUploaded){
        let sizePictureMb = (pictureUploaded.size/1000000).toFixed(2)
        // let sizePictureKb = (pictureUploaded.size/1000).toFixed(2)
        if(sizePictureMb > 2){
          this.$toast.warning("Veuillez svp choisir une image dont la taille ne dépasse pas 2Mb !", {
            icon: "fas fa-2x fa-exclamation",
          });
        }else {
          this.pictures[num_pic].pictureUrl = URL.createObjectURL(pictureUploaded);
          this.pictures[num_pic].picture = pictureUploaded;
        }
      }
    },
    onUploadPicture(artwork_id, artwork_name){
      for (let i=0; i<this.pictures.length; i++){
        if(this.pictures[i].picture){
          let formData = new FormData()
          formData.append('artwork_id', artwork_id)
          formData.append('artwork_name', artwork_name)
          formData.append('artwork_picture_id', i+"")
          formData.append('file_uploaded', this.pictures[i].picture)
          formData.append('category_file', 'artworks')
          this.axiosPostFileData({data: formData, dataForm: this.dataForm})
            .then(response => {
              if(response.status === 200){
                this.tryAutoLogin()
                this.$toast.success(response.data.message, {
                  icon: "fas fa-2x fa-check-circle",
                });
              }else {
                this.$toast.error(response.data.message, {
                  icon: "fas fa-2x fa-times-circle",
                });
              }
            })
            .catch(error => {
              this.loading = false
            })
        }
      }
    },
    onSubmitArtWorkNow() {
      this.loading = true;
      this.axiosPostData(this.dataForm)
          .then( response => {
            if(response.status === 200){
              this.$toast.success(response.data.message, {
                icon: "fas fa-2x fa-check-circle",
              });
              this.onUploadPicture(response.data.data.artwork_id, response.data.data.name)
            }else {
              this.$toast.error(response.data.message, {
                icon: "fas fa-2x fa-times-circle",
              });
            }
            this.loading = false;
          })
          .catch()
    },
  },
  mounted() {},
  created() {
    this.axiosGetAllData({table: "retrieveCategories"})
        .then( response => {
          if(response.status === 200){
            this.allCategories = response.data.data
          }
          this.loading = false;
        })
        .catch()
    this.dataForm.user_id = this.getToken
  },
};
</script>
